import { StudioMessageCollection } from '~/messages.types';

/**
 * Abstraction of strictly typed postMessage. The types are all to be defined in messages.types
 * Not global.types, which only contains a alias shortcut
 */
const msg = (msgObj: StudioMessageCollection) => window.postMessage(msgObj, window.origin);

export const msgParent = (msgObj: StudioMessageCollection) =>
  window.parent.postMessage(msgObj, window.parent.origin);

export default msg;
